import * as React from 'react';
import classnames from 'classnames';

import {
  ChatbotDispatch,
  Message,
  BrandingSettings,
  HandlePostMessage,
} from 'types';
import last from 'lodash/last';

import ChatbotDefaultAvatar from 'components/ChatbotDefaultAvatar/ChatbotDefaultAvatar';

import {isDecodableRDCS} from 'utils/draft';
import {checkIfImageExists} from 'utils/image';
import {faqApi} from 'utils/faq-api';
import tCss from './Thread.module.css';

import {ReadOnlyDraftEditor} from 'components/Chatbot/Chatbot';

import {useChatbotState} from 'context/ChatbotState';
import {useBranding} from 'context/Branding';
import {LinkifyView} from 'utils/Linkify';
import {
  Accessory,
  messageTypesWithAccessory,
} from 'components/Accessories/Accessories';
import MarkdownView from 'components/MarkdownView/MarkdownView';
import {ReactComponent as SenseIcon} from 'images/sense-icon.svg';
import {ReactComponent as DefaultAvatar} from 'images/silhouette.svg';

import {Avatar} from '@spaced-out/ui-design-system/lib/components/Avatar';

export function Thread({
  className,
  dispatch,
  messages,
  firstName,
  isLoading,
  onPostMessage,
  inputValue,
  isLongMultipleChoice,
  scrollThreadToCurrent,
}: {
  className?: string;
  messages: Message[];
  dispatch: ChatbotDispatch;
  firstName: string | void;
  isLoading: unknown;
  onPostMessage: HandlePostMessage;
  inputValue: string;
  isLongMultipleChoice: boolean;
  scrollThreadToCurrent: () => void;
}) {
  const brandingSettings = useBranding();
  const buttonStyle = brandingSettings.button_color
    ? {
        backgroundColor: brandingSettings.button_color,
        color: 'white',
      }
    : {};

  const avatar = brandingSettings.chatbot_avatar ? (
    <img
      className={tCss.favicon}
      src={brandingSettings.chatbot_avatar}
      alt="Chatbot avatar"
    />
  ) : (
    <SenseIcon />
  );

  const lastMessage = last(messages);
  const hasAccessory =
    lastMessage?.direction === 'incoming' &&
    lastMessage?.type &&
    messageTypesWithAccessory.includes(lastMessage.type);

  return (
    <div
      className={classnames(tCss.root, className)}
      aria-live="polite"
      tabIndex={0}
    >
      <p className={tCss.timestampSubtitle} tabIndex={0}>
        {new Date().toLocaleDateString(undefined, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          weekday: 'long',
        })}
      </p>
      <Messages
        dispatch={dispatch}
        messages={messages}
        avatar={avatar}
        branding_settings={brandingSettings}
        firstName={firstName}
        onPostMessage={onPostMessage}
        scrollThreadToCurrent={scrollThreadToCurrent}
      />

      {isLoading ? (
        <SoloMessageContainer
          avatar={avatar}
          branding_settings={brandingSettings}
          isFirstInGroup
        >
          <TypingAnimation />
        </SoloMessageContainer>
      ) : lastMessage &&
        lastMessage.direction === 'incoming' &&
        ['lat-wait-start', 'lat-agent-drop', 'scheduler'].includes(
          lastMessage.type,
        ) ? (
        <BareAccessoryContainer>
          <Accessory
            isLast={true}
            accessory={lastMessage}
            onSubmit={onPostMessage}
            buttonStyle={buttonStyle}
            handleScrollToMessage={scrollThreadToCurrent}
            dispatch={dispatch}
          />
        </BareAccessoryContainer>
      ) : (
        lastMessage &&
        lastMessage.direction === 'incoming' &&
        hasAccessory &&
        !isLongMultipleChoice && (
          <SoloMessageContainer
            avatar={avatar}
            branding_settings={brandingSettings}
          >
            <Accessory
              isLast={true}
              accessory={lastMessage}
              onSubmit={onPostMessage}
              buttonStyle={buttonStyle}
              handleScrollToMessage={scrollThreadToCurrent}
              dispatch={dispatch}
            />
          </SoloMessageContainer>
        )
      )}
    </div>
  );
}

function Messages({
  dispatch,
  messages,
  firstName,
  avatar,
  onPostMessage,
  scrollThreadToCurrent,
  branding_settings,
}: {
  dispatch: ChatbotDispatch;
  messages: Message[];
  avatar: React.ReactNode;
  branding_settings: BrandingSettings;
  firstName: string | void;
  onPostMessage: HandlePostMessage;
  scrollThreadToCurrent: () => void;
}) {
  const dateFormatter = new Intl.DateTimeFormat(undefined, {
    hour: 'numeric',
    minute: 'numeric',
  });
  const brandingSettings = useBranding();

  const {state} = useChatbotState();
  const agents = state?.agents ?? {};

  return (
    <>
      {messages.map((message, index) => {
        const nextMessage = messages[index + 1];
        const prevMessage = messages[index - 1];

        const isLastMessage = index === messages.length - 1;
        const isIncoming = message.direction === 'incoming';
        const isFirstInGroup =
          !prevMessage ||
          prevMessage.direction !== message.direction ||
          (prevMessage.direction === 'incoming' &&
            ['lat-agent-drop', 'lat-agent-join'].includes(prevMessage.type));
        const isLastInGroup =
          !nextMessage || nextMessage.direction !== message.direction;

        const block = message.direction === 'incoming' ? message : null;
        const accessory = message.direction === 'incoming' && message;

        const hasAccessory =
          message.direction === 'incoming' &&
          message.type &&
          messageTypesWithAccessory.includes(message.type);

        if (!isLastMessage && !message.text && !block?.text) {
          return null;
        }

        if (
          !isLastMessage &&
          message.direction === 'incoming' &&
          message.type === 'lat-agent-join'
        ) {
          return null;
        }

        const bubbleClassName = isIncoming
          ? tCss.bubbleIncoming
          : tCss.bubbleOutgoing;

        const hasRichText =
          isIncoming && isDecodableRDCS(message.text_draft_json);

        // TODO(marcos) // hide the linkified view here
        const messageChunks = message.text
          ?.split(/(!\[.*?\))|(\[.*?\))/)
          .filter(Boolean);

        const mappedMessageChunks = messageChunks?.map(chunk => {
          const isGiphyChunk = /(!\[.*?\))/.test(chunk);
          const isHyperlinkChunk = /(\[.*?\))/.test(chunk);

          const url =
            (isGiphyChunk || isHyperlinkChunk) && chunk.match(/\((.*?)\)/)?.[1];
          const text = isHyperlinkChunk && chunk.match(/\[(.*?)\]/)?.[1];

          if (isGiphyChunk && url) {
            return (
              <div>
                <GiphyImage key={url} url={url} />
              </div>
            );
          } else if (isHyperlinkChunk && url) {
            return (
              <a href={url} target="_blank" rel="noopener noreferrer">
                {text}
              </a>
            );
          } else {
            return <LinkifyView key={chunk}>{chunk}</LinkifyView>;
          }
        });

        const isLAT =
          message.direction === 'incoming' &&
          message.type === 'lat-agent-message';
        // @ts-ignore
        const currentAvatar = isLAT ? (
          agents[message.metadata.agent_id]?.avatar_url != null &&
          agents[message.metadata.agent_id]?.avatar_url.trim() !== '' ? (
            <img
              // @ts-ignore
              key={agents[message.metadata.agent_id]?.avatar_url}
              className={tCss.favicon}
              // @ts-ignore
              src={agents[message.metadata.agent_id]?.avatar_url}
              alt="Live agent"
            />
          ) : (
            <Avatar
              size="small"
              text={agents[message.metadata.agent_id].name}
            />
          )
        ) : (
          avatar
        );

        const showFeedback =
          message.direction === 'incoming' &&
          // incoming messages that say things like
          // "Sorry, you provided a blank or a punctuation-only response. Please respond again."
          // return null metadata for some reason (possibly a weird exceptional code path)
          // otherwise metadata is guaranteed to be an object
          message.metadata?.event_type === 'sense_faq_action';

        if (
          message.direction === 'incoming' &&
          ['lat-wait-start', 'lat-agent-drop'].includes(message.type)
        ) {
          if (isLastMessage) {
            // don't show anything in Messages if last
            // because it's shown as the last accessory
            // in Thread
            return null;
          } else {
            return (
              <BareAccessoryContainer key={message.id}>
                <Accessory
                  isLast={isLastMessage}
                  accessory={message}
                  onSubmit={onPostMessage}
                  buttonStyle={{}}
                  handleScrollToMessage={scrollThreadToCurrent}
                  dispatch={dispatch}
                />
              </BareAccessoryContainer>
            );
          }
        }

        return (
          <>
            <MessageContainer
              key={message.id}
              isIncoming={isIncoming}
              isFirstInGroup={isFirstInGroup}
              firstName={firstName}
              avatar={currentAvatar}
              branding_settings={branding_settings}
            >
              <div
                className={classnames(
                  tCss.message,
                  isIncoming ? tCss.incoming : tCss.outgoing,
                  !hasAccessory && isLastInGroup && tCss.last,
                )}
                style={
                  message.direction === 'incoming'
                    ? {
                        color: brandingSettings.chatbot_font_color,
                        backgroundColor: brandingSettings.chatbot_bubble_color,
                      }
                    : {}
                }
              >
                {message.text &&
                  (message.direction === 'incoming' &&
                  message.type === 'summary' ? (
                    <MarkdownView tagName="div" className={tCss.bubbleMarkdown}>
                      {message.text}
                    </MarkdownView>
                  ) : hasRichText ? (
                    <ReadOnlyDraftEditor
                      rte_json={message.text_draft_json}
                      className={tCss.richText}
                    />
                  ) : (
                    <p className={bubbleClassName}>{mappedMessageChunks}</p>
                  ))}
                {isLastInGroup && !accessory && (
                  <span className={tCss.timestamp}>
                    {dateFormatter.format(message.timeCreated)}
                  </span>
                )}
              </div>
            </MessageContainer>
            {showFeedback && <Feedback key={message.id} message={message} />}
            {!isLastMessage &&
              hasAccessory &&
              message.direction === 'incoming' &&
              message.type === 'job_match' &&
              Number(message.matches_count) > 0 && (
                <SoloMessageContainer
                  key={message.id}
                  avatar={currentAvatar}
                  branding_settings={branding_settings}
                >
                  {accessory && (
                    <Accessory
                      isLast={isLastMessage}
                      accessory={accessory}
                      onSubmit={onPostMessage}
                      buttonStyle={{}}
                      handleScrollToMessage={scrollThreadToCurrent}
                      dispatch={dispatch}
                    />
                  )}
                </SoloMessageContainer>
              )}
          </>
        );
      })}
    </>
  );
}

function Feedback({message}: {message: Message}) {
  const {state} = useChatbotState();
  const conversation = state?.conversation;

  const [feedbackChoice, setFeedbackChoice] = React.useState<string | null>(
    null,
  );
  const handleSaveFeedback = (choice: string) => {
    if (conversation) {
      faqApi
        .post(`/faq-feedback`, {
          agency_id: conversation.agency_id,
          flow_id: conversation?.flow?.flow?.id,
          message_id: message.direction !== 'outgoing' && message.message_id,
          disposition: choice,
        })
        .then(res => {
          setFeedbackChoice(choice);
        })
        .catch(err => console.error(err));
    }
  };
  return (
    <p className={tCss.feedback}>
      {feedbackChoice === null ? (
        <>
          {' '}
          Was this helpful?{' '}
          <span
            className={tCss.feedbackOption}
            onClick={() => handleSaveFeedback('positive')}
          >
            👍
          </span>{' '}
          <span
            className={tCss.feedbackOption}
            onClick={() => handleSaveFeedback('negative')}
          >
            👎
          </span>
        </>
      ) : feedbackChoice === 'positive' ? (
        '👍 Thanks for the feedback!'
      ) : (
        "👎 I'm sorry, I'll try to do better"
      )}
    </p>
  );
}

function GiphyImage({url}: {url: string}) {
  const [isImageValid, setIsImageValid] = React.useState(false);

  React.useEffect(() => {
    checkIfImageExists(url, exists => {
      if (exists) {
        setIsImageValid(true);
      }
    });
  }, [url]);

  return isImageValid ? <img src={url} className={tCss.gif} /> : null;
}

export function TypingAnimation({className}: {className?: string}) {
  return (
    <div className={classnames(tCss.message, tCss.incoming, className)}>
      <TypingBubble />
    </div>
  );
}

function TypingBubble() {
  const height = 14;
  return (
    <div className={tCss.bubble}>
      <svg width={height * 3} height={height} viewBox="0 0 300 100">
        <TypingCircle position={0} />
        <TypingCircle position={1} />
        <TypingCircle position={2} />
      </svg>
    </div>
  );
}

function TypingCircle({position}: {position: number}) {
  return (
    <circle
      className={tCss.circle}
      style={{animationDelay: `${position * 0.2}s`}}
      cx={position * 100 + 50}
      cy="50"
      r="25"
    />
  );
}

function BareAccessoryContainer(props: {children: React.ReactNode}) {
  return (
    <div className={tCss.bareAccessoryContainer} tabIndex={0}>
      {props.children}
    </div>
  );
}

function SoloMessageContainer(props: {
  children: React.ReactNode;
  isFirstInGroup?: boolean;
  avatar: React.ReactNode;
  branding_settings: BrandingSettings;
}) {
  return (
    <div className={tCss.messageContainerLoading} tabIndex={0}>
      <MessageContainer isIncoming {...props} />
    </div>
  );
}

export function MessageContainer({
  children,
  isIncoming,
  isFirstInGroup,
  firstName,
  avatar,
  branding_settings,
}: {
  children: React.ReactNode;
  isIncoming?: boolean;
  isFirstInGroup?: boolean;
  firstName?: string | void;
  avatar: React.ReactNode;
  branding_settings: BrandingSettings;
}) {
  const brandingSettings = useBranding();

  return (
    <div
      className={
        isIncoming
          ? tCss.messageContainerIncoming
          : tCss.messageContainerOutgoing
      }
      tabIndex={0}
    >
      <div
        className={classnames(
          tCss.namedAvatar,
          !isIncoming && tCss.namedAvatarOutgoing,
        )}
      >
        <div
          className={classnames(
            tCss.avatar,
            isFirstInGroup && tCss.avatarFirst,
            !isIncoming && tCss.avatarOutgoing,
          )}
        >
          {isFirstInGroup ? (
            isIncoming ? (
              brandingSettings.chatbot_avatar ? (
                avatar
              ) : (
                <ChatbotDefaultAvatar
                  className={tCss.chatbotAvatar}
                  color={brandingSettings.color}
                />
              )
            ) : firstName ? (
              <DefaultAvatar />
            ) : (
              <DefaultAvatar />
            )
          ) : null}
        </div>
      </div>
      {children}
    </div>
  );
}
